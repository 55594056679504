import { z } from 'zod';

export const emailSchema = z
  .string({
    message: 'Adres e-mail jest wymagany',
  })
  .email({ message: 'Błędny adres e-mail' });

export const loginFormSchema = z.object({
  email: emailSchema,
  password: z.string({ message: 'Hasło jest wymagane' }),
  remember: z.boolean().optional(),
});

export const loginCodeFormSchema = z.object({
  email: emailSchema,
  code: z.string({ message: 'Podaj kod weryfikacyjny' }).length(6, {
    message: 'Kod weryfikacyjny musi mieć 6 znaków',
  }),
});

export const sendTokenPasswordFormSchema = z.object({
  email: emailSchema,
});

export const tokensDataSchema = z.object({
  token: z.string(),
  refresh_token: z.string(),
  token_expires_at: z.string(),
});

export const resetPasswordSchema = z
  .object({
    password: z.string({ message: 'Hasło jest wymagane' }).min(8, {
      message: 'Hasło musi mieć co najmniej 8 znaków',
    }),
    password_confirmation: z.string({
      message: 'Potwierdzenie hasła jest wymagane',
    }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Hasła różnią się od siebie',
    path: ['password_confirmation'],
  });

export const userSchema = z.object({
  name: z.string(),
  last_name: z.string().nullable(),
  email: emailSchema,
});

export const schematsStatusSchema = z.union([
  z.literal('active'),
  z.literal('draft'),
  z.literal('template'),
]);

export const schematSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: schematsStatusSchema.nullable(),
});

export const schematsSchema = z.array(schematSchema);

export const detailedSchematSchema = schematSchema.and(
  z.object({
    screening_names: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        schemat_details: z.array(
          z.object({
            id: z.number(),
            week: z.union([z.number(), z.string()]).nullable(),
            study_day: z.union([z.number(), z.string()]).nullable(),
            visit_window: z.union([z.number(), z.string()]).nullable(),
            visit_name: z.string().nullable(),
          }),
        ),
      }),
    ),
  }),
);

export const weekSchema = z.object({
  id: z.number().optional(),
  week: z.union([z.number(), z.string()]).nullable(),
  study_day: z.union([z.number(), z.string()]).nullable(),
  visit_window: z.union([z.number(), z.string()]).nullable(),
  visit_name: z.string({ message: 'Nazwa wizyty jest wymagana' }).nullable(),
});

export const screeningSchema = z.object({
  id: z.number().optional(),
  name: z.string({ message: 'Nazwa badania jest wymagana' }),
  schemat_details: z.array(weekSchema),
});

export const addSchematFormSchema = z.object({
  name: z.string({ message: 'Nazwa schematu jest wymagana' }),
  schemat_screening_names: z.array(screeningSchema),
  status: schematsStatusSchema,
});

export const storeScreeningResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const surveyFormSchema = z.object({
  name: z.string({ message: 'Nazwa badania jest wymagana' }).min(1, {
    message: 'Nazwa badania jest wymagana',
  }),
  description: z.string({ message: 'Opis badania jest wymagany' }).min(1, {
    message: 'Opis badania jest wymagany',
  }),
  medic_id: z.number({ message: 'Główny badacz jest wymagany' }),
  coordinator_id: z.number({ message: 'Główna koordynator jest wymagany' }),
  schemat_ids: z
    .array(z.number(), {
      message: 'Schemat jest wymagany',
    })
    .min(1, {
      message: 'Schemat jest wymagany',
    }),
  medic_ids: z.array(z.number()),
  nurse_ids: z.array(z.number()),
  coordinator_ids: z.array(z.number()),
  duration: z
    .string({
      message: 'Czas trwania badania jest wymagany',
    })
    .min(1, {
      message: 'Czas trwania badania jest wymagany',
    }),
});

export const personelSchema = z.object({
  email: emailSchema,
  name: z.string(),
  last_name: z.string().nullable(),
  roles: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .nullable(),
  id: z.number(),
});

export const personelsSchema = z.array(personelSchema);

export const surveySchema = z.object({
  name: z.string(),
  description: z.string(),
  medic: personelSchema,
  coordinator: personelSchema,
  coordinators: z.array(personelSchema),
  medics: z.array(personelSchema),
  nurses: z.array(personelSchema),
  id: z.number(),
  schemats: schematsSchema,
  slug: z.string().optional(),
  duration: z.string(),
});

export const surveysSchema = z.array(surveySchema);

export const medicineSchema = z.object({
  id: z.number(),
  name: z.string(),
  dose: z.number().nullable(),
  unit: z.string(),
  surveys: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    )
    .nullable(),
});

export const medicinesSchema = z.array(medicineSchema);

export const medicineFormSchema = z.object({
  name: z.string({ message: 'Nazwa leku jest wymagana' }),
  dose: z.number({ message: 'Dawka jest wymagana' }),
  unit: z.string({ message: 'Jednostka jest wymagana' }),
  survey_ids: z.array(z.number()).nullable(),
});

export const formQuestionAnswerSchema = z.object({
  id: z.number(),
  answer: z.string(),
  order: z.number(),
  is_required_contact: z.boolean().nullable(),
});

export const formFormQuestionAnswerSchema = z.object({
  answer: z.string().optional(),
  order: z.number(),
  is_required_contact: z.boolean().nullable().optional(),
});

export const formQuestionSchema = z.object({
  id: z.number(),
  order: z.number(),
  is_required: z.boolean(),
  form_question_answers: z.array(formQuestionAnswerSchema),
  type: z.string(),
  question: z.string(),
});

export const formFormQuestionSchema = z.object({
  order: z.number(),
  is_required: z.boolean(),
  type: z.string(),
  question: z.string({ message: 'Tytuł pytania jest wymagany' }),
  form_question_answers: z.array(formFormQuestionAnswerSchema),
});

export const formTemplateQuestionSchema = z.object({
  id: z.number(),
  order: z.number(),
  is_required: z.boolean(),
  form_question_answer_templates: z.array(formQuestionAnswerSchema),
  type: z.string(),
  question: z.string(),
});

export const formTemplateSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  designation: z.string().nullable(),
  start_date: z.string().nullable(),
  end_date: z.string().nullable(),
  is_multiple_answer: z.boolean().nullable(),
  form_question_templates: z.array(formTemplateQuestionSchema),
  is_recurring: z.boolean().nullable(),
  recurring_days_of_week: z.array(z.number()).nullable(),
  recurring_interval: z.number().nullable(),
  recurring_type: z.string().nullable(),
  schemat_id: z.number().nullable(),
  type: z.string(),
});

export const formTemplatesSchema = z.array(formTemplateSchema);

export const formTemplatesMetaSchema = z.object({
  data: z.array(formTemplateSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const formSchema = z.object({
  id: z.number(),
  name: z.string(),
  total_form_user_results_count: z.number().nullable(),
  description: z.string().nullable(),
  designation: z.string().nullable(),
  start_date: z.string().nullable(),
  start_time: z.string().nullable(),
  end_date: z.string().nullable(),
  form_template_id: z.number().nullable(),
  is_active: z.boolean(),
  is_multiple_answer: z.boolean().nullable(),
  form_questions: z.array(formQuestionSchema),
  form_users: z.array(
    z.object({
      id: z.number(),
      user: userSchema,
      is_disabled: z.boolean().nullable(),
      created_at: z.string().nullable(),
      form_user_results: z.array(
        z.object({
          id: z.number(),
          result: z.record(z.string(), z.array(z.string())),
        }),
      ),
    }),
  ),
  is_recurring: z.boolean().nullable(),
  medicine: z.object({ id: z.number() }).nullable(),
  recurring_days_of_week: z.array(z.number()).nullable(),
  recurring_interval: z.number().nullable(),
  recurring_type: z.string().nullable(),
  schemat_id: z.number().nullable(),
  survey_id: z.number().nullable(),
  medicine_id: z.number().nullable(),
  send_for: z.string().nullable(),
  send_user_ids: z.array(z.number()).nullable(),
  type: z.string(),
});

export const formsSchema = z.array(formSchema);

export const formMetaSchema = z.object({
  data: z.array(formSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const formFormSchema = z
  .object({
    name: z.string().nullable().optional(),
    designation: z.string().nullable().optional(),
    form_template_id: z.number().nullable().optional(),
    description: z.string().nullable().optional(),
    is_active: z.boolean().nullable().optional(),
    form_questions: z.array(formFormQuestionSchema).nullable().optional(),
    start_date: z.date().nullable().optional(),
    start_time: z.date().nullable().optional(),
    end_date: z.date().nullable().optional(),
    is_recurring: z.boolean().nullable().optional(),
    recurring_type: z.string().nullable().optional(),
    recurring_interval: z.number().nullable().optional(),
    recurring_days_of_week: z.array(z.number()).nullable().optional(),
    is_multiple_answer: z.boolean().nullable().optional(),
    type: z.string().nullable().optional(),
    send_for: z.string().nullable().optional(),
    medicine_id: z.number().nullable().optional(),
    schemat_id: z.number().nullable().optional(),
    send_user_ids: z.array(z.number()).nullable().optional(),
    survey_id: z.number().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.designation) {
      ctx.addIssue({
        path: ['designation'],
        message: 'Oznaczenie jest wymagane',
        code: 'custom',
      });
    }
    if (!data.name) {
      ctx.addIssue({
        path: ['name'],
        message: 'Nazwa jest wymagana',
        code: 'custom',
      });
    }
    if (!data.form_questions || data.form_questions.length === 0) {
      ctx.addIssue({
        path: ['form_questions'],
        message: 'Wymagane jest choć jedno pytanie',
        code: 'custom',
      });
    } else {
      for (let i = 0; i < data.form_questions.length; i++) {
        if (!data.form_questions[i].question) {
          ctx.addIssue({
            path: ['form_questions'],
            message: 'Tytuł pytania jest wymagany',
            code: 'custom',
          });
        }
        if (
          !data.form_questions[i]?.form_question_answers?.length &&
          data.form_questions[i].type !== 'date'
        ) {
          ctx.addIssue({
            path: ['form_questions'],
            message: 'Proszę podać choć jedną odpowiedź',
            code: 'custom',
          });
        } else if (data.form_questions[i].type !== 'date') {
          for (
            let z = 0;
            z < data.form_questions[i].form_question_answers.length;
            z++
          ) {
            if (!data.form_questions[i].form_question_answers[z].answer) {
              ctx.addIssue({
                path: ['form_questions'],
                message: 'Odpowiedź na pytanie musi być wprowadzona',
                code: 'custom',
              });
            }
          }
        }
      }
    }

    if (['own', 'well_being'].includes(String(data.type))) {
      if (!data.start_date) {
        ctx.addIssue({
          path: ['start_date'],
          message: 'Data rozpoczęcia jest wymagana',
          code: 'custom',
        });
      }
      if (!data.start_time) {
        ctx.addIssue({
          path: ['start_time'],
          message: 'Godzina rozpoczęcia jest wymagana',
          code: 'custom',
        });
      }
      if (!data.end_date) {
        ctx.addIssue({
          path: ['end_date'],
          message: 'Data zakończenia jest wymagana',
          code: 'custom',
        });
      }
      if (
        data.recurring_type === 'choice_day' &&
        (!data.recurring_days_of_week ||
          data.recurring_days_of_week.length === 0)
      ) {
        ctx.addIssue({
          path: ['recurring_days_of_week'],
          message: 'Wymagany jest choć jeden dzień tygodnia',
          code: 'custom',
        });
      }
      if (
        data.send_for === 'users' &&
        (!data.send_user_ids || data.send_user_ids.length === 0)
      ) {
        ctx.addIssue({
          path: ['send_user_ids'],
          message: 'Pacjenci są wymagani',
          code: 'custom',
        });
      }
      if (data.send_for === 'all' && !data.schemat_id) {
        ctx.addIssue({
          path: ['schemat_id'],
          message: 'Schemat jest wymagany',
          code: 'custom',
        });
      }
    } else if (data.type === 'medicine') {
      if (!data.medicine_id) {
        ctx.addIssue({
          path: ['medicine_id'],
          message: 'Wymagany jest wybór leku',
          code: 'custom',
        });
      }
      if (!data.schemat_id) {
        ctx.addIssue({
          path: ['schemat_id'],
          message: 'Schemat jest wymagany',
          code: 'custom',
        });
      }
    } else if (data.type === 'adverse_event') {
      if (!data.survey_id) {
        ctx.addIssue({
          path: ['survey_id'],
          message: 'Badanie jest wymagane',
          code: 'custom',
        });
      }
    }
  });

export const personelMetaSchema = z.object({
  data: z.array(personelSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const workScheduleFormSchema = z.object({
  user_id: z.number(),
  dates: z.array(
    z.object({
      date: z.string(),
      start_time: z.string(),
      end_time: z.string(),
    }),
  ),
});

export const defaultNotificationSchema = z
  .object({
    title: z.string().optional(),
    designation: z.string().optional(),
    body: z.string().optional(),
    type: z.enum(['email', 'email_push', 'push']).optional(),
    is_recurring: z.boolean().optional(),
    recurring_type: z
      .enum(['choice_day', 'quantity_day', 'calendar_days'])
      .nullable()
      .optional(),
    recurring_interval: z.number().nullable().optional(),
    recurring_days_of_week: z.array(z.number()).nullable().optional(),
    recurring_calendar_days: z.array(z.date()).nullable().optional(),
    send_for: z.enum(['users', 'all']).optional(),
    send_user_ids: z.array(z.number()).optional(),
    schemat_id: z.number().nullable().optional(),
    end_date: z.date().nullable().optional(),
    start_date: z.date().nullable().optional(),
    start_time: z.date().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.title) {
      ctx.addIssue({
        code: 'custom',
        path: ['title'],
        message: 'Tytuł jest wymagany',
      });
    }

    if (!data.designation) {
      ctx.addIssue({
        code: 'custom',
        path: ['designation'],
        message: 'Nazwa powiadomienia jest wymagana',
      });
    }

    if (!data.body) {
      ctx.addIssue({
        code: 'custom',
        path: ['body'],
        message: 'Treść powiadomienia jest wymagana',
      });
    }

    if (!['email', 'email_push', 'push'].includes(String(data.type))) {
      ctx.addIssue({
        code: 'custom',
        path: ['type'],
        message: 'Typ powiadomienia jest wymagany',
      });
    }

    if (data.is_recurring !== true && data.is_recurring !== false) {
      ctx.addIssue({
        code: 'custom',
        path: ['is_recurring'],
        message: 'Rodzaj powiadomienia jest wymagany',
      });
    }

    if (data.is_recurring && !data.recurring_type) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_type'],
        message: 'Rodzaj powtarzania jest wymagany',
      });
    }

    if (data.recurring_type === 'quantity_day' && !data.recurring_interval) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_interval'],
        message: 'Ilość dni jest wymagana',
      });
    }

    if (
      data.recurring_type === 'choice_day' &&
      !data.recurring_days_of_week?.length
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_days_of_week'],
        message: 'Dni tygodnia są wymagane',
      });
    }

    if (
      data.recurring_type === 'calendar_days' &&
      !data.recurring_calendar_days?.length
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_calendar_days'],
        message: 'Dni kalendarzowe są wymagane',
      });
    }

    if (!data.send_for) {
      ctx.addIssue({
        code: 'custom',
        path: ['send_for'],
        message: 'Odbiorcy są wymagani',
      });
    }

    if (data.send_for === 'users' && !data.send_user_ids?.length) {
      ctx.addIssue({
        code: 'custom',
        path: ['send_user_ids'],
        message: 'Odbiorcy są wymagani',
      });
    }

    if (data.send_for === 'all' && !data.schemat_id) {
      ctx.addIssue({
        code: 'custom',
        path: ['schemat_id'],
        message: 'Schemat jest wymagany',
      });
    }

    if (data.recurring_type !== 'calendar_days' && !data.start_date) {
      ctx.addIssue({
        code: 'custom',
        path: ['start_date'],
        message: 'Data rozpoczęcia jest wymagana',
      });
    }

    if (
      data.is_recurring &&
      data.recurring_type !== 'calendar_days' &&
      !data.end_date
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['end_date'],
        message: 'Data zakończenia jest wymagana',
      });
    }

    if (!data.start_time) {
      ctx.addIssue({
        code: 'custom',
        path: ['start_time'],
        message: 'Godzina powiadomienia jest wymagana',
      });
    }
  });

export const formtNotificationSchema = z.object({
  title: z
    .string({
      message: 'Tytuł powiadomienia jest wymagany',
    })
    .min(1, {
      message: 'Tytuł powiadomienia jest wymagany',
    }),
  designation: z
    .string({
      message: 'Nazwa powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Nazwa powiadomienia jest wymagana',
    }),
  body: z
    .string({
      message: 'Treść powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Treść powiadomienia jest wymagana',
    }),
  type: z.enum(['email', 'email_push', 'push'], {
    message: 'Typ powiadomienia jest wymagany',
  }),
  form_id: z.number({
    message: 'Formularz jest wymagany',
  }),
});

export const visitNotificationSchema = z.object({
  title: z
    .string({
      message: 'Tytuł powiadomienia jest wymagany',
    })
    .min(1, {
      message: 'Tytuł powiadomienia jest wymagany',
    }),
  designation: z
    .string({
      message: 'Nazwa powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Nazwa powiadomienia jest wymagana',
    }),
  body: z
    .string({
      message: 'Treść powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Treść powiadomienia jest wymagana',
    }),
  type: z.enum(['email', 'email_push', 'push'], {
    message: 'Typ powiadomienia jest wymagany',
  }),
  schemat_id: z.number({
    message: 'Schemat badania jest wymagany',
  }),
  quantity_days: z.number({
    message: 'Ilość dni jest wymagana',
  }),
});

export const notificationScheduleStatusSchema = z.enum([
  'unprocessed',
  'success',
  'failed',
]);

export const notificationScheduleSchema = z.object({
  id: z.number(),
  user: z.object({
    name: z.string(),
    last_name: z.string().nullable(),
    email: z.string(),
  }),
  status: notificationScheduleStatusSchema,
});

export const notificationTemplateSchema = z.object({
  id: z.number(),
  schemat_id: z.number().nullable(),
  body: z.string(),
  title: z.string(),
  designation: z.string(),
  is_recurring: z.boolean().nullable(),
  kind_type: z.enum(['default', 'form', 'visit']),
  recurring_calendar_days: z.array(z.string()).nullable(),
  recurring_days_of_week: z.array(z.number()).nullable(),
  recurring_interval: z.number().nullable(),
  recurring_type: z
    .enum(['choice_day', 'quantity_day', 'calendar_days'])
    .nullable(),
  start_date: z.string().nullable(),
  start_time: z.string().nullable(),
  type: z.enum(['email', 'email_push', 'push']),
  form_id: z.number().nullable(),
  send_for: z.enum(['users', 'all']).nullable(),
  send_user_ids: z.array(z.number()).nullable(),
  end_date: z.string().nullable(),
  quantity_days: z.number().nullable(),
});

export const notificationCampaignSchema = z.object({
  ...notificationTemplateSchema.shape,
  notification_schedules: z.array(notificationScheduleSchema),
});

export const metaNotificationCampaignSchema = z.object({
  data: z.array(notificationCampaignSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const metaTemplateNotificationCampaignSchema = z.object({
  data: z.array(notificationTemplateSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const notificationTemplateFormSchema = z.object({
  template_id: z.number({
    message: 'Szablon powiadomienia jest wymagany',
  }),
});

export const visitFormSchema = z.object({
  patient_id: z.number({ message: 'Pacjent jest wymagany' }),
  survey_type_id: z.number().nullable().optional(),
  medic_ids: z.array(z.number()),
  title: z.string().optional(),
  description: z.string().optional(),
  info: z.string().optional(),
  equipment: z.string().optional(),
  activity: z.string().optional(),
  notes: z.string().optional(),
  date: z.string().nullable().optional(),
  medicine_ids: z.array(z.number()),
  nurse_ids: z.array(z.number()),
  visit_start_time: z.string().nullable().optional(),
  visit_end_time: z.string().nullable().optional(),
  files: z.array(z.any()),
});

export const visitStatusSchema = z.union([
  z.literal('to_plan'),
  z.literal('planned'),
  z.literal('not_confirmed'),
  z.literal('confirmed'),
  z.literal('rejected'),
]);

export const visitSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  info: z.string().nullable(),
  equipment: z.string().nullable(),
  activity: z.string().nullable(),
  notes: z.string().nullable(),
  date: z.string().nullable(),
  visit_start_time: z.string().nullable(),
  visit_end_time: z.string().nullable(),
  medicines: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  medics: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      last_name: z.string().nullable(),
    }),
  ),
  nurses: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  // survey_type: z
  //   .object({
  //     id: z.number(),
  //     name: z.string(),
  //   })
  //   .nullable(),
  patient: z.object({
    id: z.number(),
    name: z.string(),
    last_name: z.string().nullable(),
  }),
  status: visitStatusSchema.nullable(),
  files: z.array(z.any()).nullable(),
});

export const visitsSchema = z.array(visitSchema);

export const patientUserFormSchema = z.object({
  id: z.number(),
  form: z.object({
    name: z.string(),
    created_at: z.string().nullable(),
    type: z.string(),
  }),
  user: userSchema,
  form_user_results: z.array(
    z.object({
      id: z.number(),
      result: z.record(z.string(), z.array(z.string())),
    }),
  ),
  created_at: z.string().nullable(),
  is_disabled: z.boolean().nullable(),
});

export const patientSchema = z.object({
  id: z.number(),
  name: z.string(),
  last_name: z.string().nullable(),
  email: z.string(),
  phone: z.string().nullable(),
  mrn: z.string(),
  patient_survey: z.array(
    z.object({
      survey_id: z.number(),
      schemat_id: z.number(),
      survey: z.object({
        id: z.number(),
        name: z.string(),
      }),
      schemat: z.object({
        id: z.number(),
        name: z.string(),
      }),
    }),
  ),
  permissions: z.object({
    can_create: z.boolean(),
    can_edit: z.boolean(),
    can_delete: z.boolean(),
  }),
  birth_date: z.string(),
  form_users: z.array(patientUserFormSchema).nullable(),
  visits: z
    .array(
      visitSchema.omit({
        patient: true,
      }),
    )
    .nullable(),
});

export const patientsSchema = z.array(patientSchema);

export const patientMetaSchema = z.object({
  data: z.array(patientSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const workScheduleSchema = z.object({
  id: z.number(),
  user: personelSchema,
  date: z.string(),
  start_time: z.string(),
  end_time: z.string(),
});

export const workSchedulesSchema = z.array(workScheduleSchema);

export const symptomSchema = z.object({
  id: z.number(),
  name: z.string({ message: 'Nazwa objawu jest wymagana' }),
  surveys: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      slug: z.string(),
    }),
  ),
});

export const symptomsSchema = z.array(symptomSchema);

export const symptomFormSchema = z.object({
  name: z
    .string({ message: 'Nazwa objawu jest wymagana' })
    .min(1, { message: 'Nazwa objawu jest wymagana' }),
  survey_ids: z.array(z.number()),
});

export const userWorkScheduleSchema = z.array(
  z.object({
    date: z.string(),
    end_time: z.string(),
    start_time: z.string(),
  }),
);

export const patientFormEditSchema = z.object({
  name: z
    .string({ message: 'Nazwa pacjenta jest wymagana' })
    .min(1, { message: 'Nazwa pacjenta jest wymagana' }),
  last_name: z.string().optional(),
  email: emailSchema,
  phone: z
    .string()
    .optional()
    .refine((val) => !val || /^\d{9}$/.test(val), {
      message: 'Niepoprawny numer telefonu',
    }),
  birth_date: z.string({ message: 'Data urodzenia jest wymagana' }),
});

export const patientFormSchema = z.object({
  ...patientFormEditSchema.shape,
  survey_id: z.number({
    message: 'Badanie jest wymagane',
  }),
  schemat_id: z.number({
    message: 'Schemat jest wymagany',
  }),
});

export const notificationSchema = z.object({
  id: z.number(),
  is_read: z.boolean(),
  title: z.string(),
  content: z.string(),
  url: z.string(),
  notification_type: z.string(),
  created_at: z.string(),
});

export const notificationsSchema = z.array(notificationSchema);
