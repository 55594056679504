import { ZodSchema } from 'zod';
import { ResponseError } from './responseError';
import type { HTTPMethod } from '~/utils/types';
import { fromZodError } from 'zod-validation-error';
import * as Sentry from '@sentry/vue';
import { useRuntimeConfig, useRouter } from '#app';

type FetcherConfig<Schema extends ZodSchema<any, any> | null> = {
  readonly method: HTTPMethod;
  readonly schema?: Schema;
  readonly body?: object;
  readonly config?: RequestInit;
  readonly customApiUrl?: string;
  readonly meta?: boolean;
};

let refreshingTokenPromise: Promise<string | void> | null = null;

async function refreshAccessToken(url: string) {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    throw new ResponseError('Brak refresh tokena', 401);
  }

  const response = await fetch(`${url}/refresh-token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${refreshToken ? `Bearer ${refreshToken}` : ''}`,
      'Allow-Access-Control-Origin': '*',
      Accept: 'application/json',
    },
  });

  if (!response.ok) {
    localStorage.setItem('token', '');
    localStorage.setItem('token_expires_at', '');
    localStorage.setItem('refresh_token', '');

    throw new ResponseError('Nie udało się odświeżyć tokena', 401);
  }

  const data = await response.json();

  localStorage.setItem('token', data.data.token);
  localStorage.setItem('token_expires_at', data.data.token_expires_at);
  localStorage.setItem('refresh_token', data.data.refresh_token);

  return data.accessToken;
}

export async function fetcher<Schema extends ZodSchema<any, any> | null>(
  path: string,
  { method, body, config, schema, customApiUrl, meta }: FetcherConfig<Schema>,
): Promise<Schema extends ZodSchema<infer T, any> ? T : null> {
  try {
    const { API_URL } = useRuntimeConfig().public;
    const hostname = window.location.hostname.split('.')[0];
    const apiUrl = `https://${hostname}.${API_URL}`;

    const tokenExpiry = localStorage.getItem('token_expires_at');

    if (tokenExpiry && !isNaN(new Date(tokenExpiry).getTime())) {
      const expiryTime = new Date(tokenExpiry).getTime();
      const currentTime = new Date().getTime();

      if (expiryTime <= currentTime) {
        if (!refreshingTokenPromise) {
          refreshingTokenPromise = refreshAccessToken(apiUrl);
        }

        await refreshingTokenPromise;

        refreshingTokenPromise = null;
      }
    }

    const token = localStorage.getItem('token');

    if (!API_URL) {
      throw new ResponseError('API_URL environment variable is not set', 111);
    }

    const headers: Record<string, string> = {
      Authorization: `${token ? `Bearer ${token}` : ''}`,
      'Allow-Access-Control-Origin': '*',
      Accept: 'application/json',
      ...(config?.headers as Record<string, string>),
    };

    // Jeśli `body` nie jest `FormData`, zakładamy JSON i ustawiamy nagłówek Content-Type
    const isFormData = body instanceof FormData;
    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
    }

    const response = await fetch(`${customApiUrl || apiUrl}${path}`, {
      ...config,
      headers,
      credentials: 'include',
      method,
      ...(body && { body: isFormData ? body : JSON.stringify(body) }),
      mode: 'cors',
    });

    const data = await response.json();

    if (response.ok) {
      if (!schema) {
        return meta ? data : data.data;
      }

      const parsedData = schema.safeParse(meta ? data : data.data);

      if (!parsedData.success) {
        console.log(data.data);
        throw new ResponseError(fromZodError(parsedData.error).toString());
      }

      return parsedData.data;
    }

    if (response.status === 422) {
      if (
        typeof data.errors === 'object' &&
        data.errors !== null &&
        Array.isArray(Object.values(data.errors)[0])
      ) {
        const errors: Record<string, string[]> = data.errors;
        throw new ResponseError(errors[Object.keys(errors)[0]][0], 422);
      }
    }

    throw new ResponseError(data.message, response.status);
  } catch (err) {
    const router = useRouter();
    console.log(err);
    console.log(path);

    if (err instanceof ResponseError) {
      if (err.status === 401 && method !== 'POST') {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expires_at');
        router.push('/login');

        throw err;
      } else if (err.status === 403) {
        showError({
          message: 'Nie masz dostępu do tej strony.',
        });

        throw err;
      } else if (err.status === 422) {
        throw err;
      } else if (method !== 'GET') {
        Sentry.captureException(err);

        throw err;
      } else if (err.status === 404) {
        Sentry.captureException(err);

        throw err;
      }
    }

    if (
      typeof err === 'object' &&
      err !== null &&
      'message' in err &&
      err?.message === 'Failed to fetch'
    ) {
      localStorage.removeItem('token');

      Sentry.captureException(err);

      showError({
        message:
          'Wystąpił błąd podczas pobierania danych. Zostałeś wylogowany. Spróbuj ponownie później.',
      });

      throw err;
    }

    Sentry.captureException(err);

    showError({
      message:
        'Coś poszło nie tak przy pobieraniu danych. Spróbuj ponownie później.',
    });

    throw err;
  }
}
